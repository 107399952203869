import React from 'react';


const Footer = () => {
  return (
    <footer>
      <br /><hr />
      <p className='center'>
        <br />Created by<br />
        <a className='credit' href='https://bradleystaples.com'>Bradley Staples</a>
      </p>
    </footer>
  );
};

export default Footer;
